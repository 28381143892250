import axios from "@axios";

import { getSiteIdsForGroups } from "@/utils/store";

export default {
  namespaced: true,
  state: {
    dates: [],
    sites: [],
    devices: [],
    metrics: [],
    pageTypes: [],
    pageTypeList: [],
    evolutions: [],
    siteGroups: [],
    totalEvents: 0,
    totalPosts: 0,
    totalEventsJ2: 0,
    totalPostsJ2: 0,
    issueModalVisibility: false,
    selectedIssueId: null,
    deleteIssueModalVisibility: false,
    issues: [],
    data: [],
  },
  getters: {
    getDates: (state) => {
      // always return the dates ordered
      return state.dates[0] < state.dates[1]
        ? [state.dates[0], state.dates[1]]
        : [state.dates[1], state.dates[0]];
    },
    getIssueModalVisibility: (state) => state.issueModalVisibility,
    getSelectedIssueId: (state) => state.selectedIssueId,
    getDeleteIssueModalVisibility: (state) => state.deleteIssueModalVisibility,
    getIssues: (state) => {
      return [...state.issues]; // Returns a new array reference
    },
    getSites: (state) => state.sites,
    getMetrics: (state) => state.metrics,
    getPageTypes: (state) => state.pageTypes,
    getPageTypeList: (state) => state.pageTypeList,
    getEvolutions: (state) => state.evolutions,
    getSiteGroups: (state) => state.siteGroups,
    getDevices: (state) => state.devices,
    getTotalEvents: (state) => state.totalEvents,
    getTotalPosts: (state) => state.totalPosts,
    getTotalEventsJ2: (state) => state.totalEventsJ2,
    getTotalPostsJ2: (state) => state.totalPostsJ2,
    getData: (state) => state.data,
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateIssueModalVisibility(state, payload) {
      state.issueModalVisibility = payload;
    },
    updateSelectedIssueId(state, payload) {
      state.selectedIssueId = payload;
    },
    updateDeleteIssueModalVisibility(state, payload) {
      state.deleteIssueModalVisibility = payload;
    },
    updateIssues(state, payload) {
      state.issues = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateMetrics(state, payload) {
      state.metrics = payload;
    },
    updatePageTypes(state, payload) {
      state.pageTypes = payload;
    },
    updatePageTypeList(state, payload) {
      state.pageTypeList = payload;
    },
    updateEvolutions(state, payload) {
      state.evolutions = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updateTotalEvents(state, payload) {
      state.totalEvents = payload;
    },
    updateTotalPosts(state, payload) {
      state.totalPosts = payload;
    },
    updateTotalEventsJ2(state, payload) {
      state.totalEventsJ2 = payload;
    },
    updateTotalPostsJ2(state, payload) {
      state.totalPostsJ2 = payload;
    },
    updateData(state, payload) {
      state.data = payload;
    },
    updateDevices(state, payload) {
      state.devices = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateDates", payload);
      }
    },
    updateIssueModalVisibility({ commit }, payload) {
      commit("updateIssueModalVisibility", payload);
    },
    updateSelectedIssueId({ commit }, payload) {
      commit("updateSelectedIssueId", payload);
    },
    updateDeleteIssueModalVisibility({ commit }, payload) {
      commit("updateDeleteIssueModalVisibility", payload);
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    updateMetrics({ commit }, payload) {
      commit("updateMetrics", payload);
    },
    updatePageTypes({ commit }, payload) {
      commit("updatePageTypes", payload);
    },
    updateEvolutions({ commit }, payload) {
      commit("updateEvolutions", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);

      const site_ids = await getSiteIdsForGroups(payload);
      commit("updateSites", site_ids);

      return site_ids;
    },
    updateTotalEvents({ commit }, payload) {
      commit("updateTotalEvents", payload);
    },
    updateTotalPosts({ commit }, payload) {
      commit("updateTotalPosts", payload);
    },
    updateTotalEventsJ2({ commit }, payload) {
      commit("updateTotalEventsJ2", payload);
    },
    updateTotalPostsJ2({ commit }, payload) {
      commit("updateTotalPostsJ2", payload);
    },
    updateData({ commit }, payload) {
      commit("updateData", payload);
    },
    updateDevices({ commit }, payload) {
      commit("updateDevices", payload);
    },
    async getPageTypeList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/web-perf/content-types", {});
      // console.log(data);
      commit("updatePageTypeList", items);
      return items;
    },
    async getIssues({ commit, state }, payload) {
      const params = {};
      if (state.sites && state.sites.length > 0) {
        params.sites = state.sites.join(",");
      }
      if (state.pageTypes && state.pageTypes.length > 0) {
        params["content_types"] = state.pageTypes.join(",");
      }
      if (state.devices && state.devices.length > 0) {
        params.devices = state.devices.join(",");
      }
      if (state.metrics && state.metrics.length > 0) {
        params.metrics = state.metrics.join(",");
      }
      const { data } = await axios.get("/web-perf/cwv-issues", {
        params,
      });
      commit("updateIssues", data.items);
    },
  },
};
