import axios from "@axios";

import { getSiteIdsForGroups } from "@/utils/store";

export default {
  namespaced: true,
  state: {
    partners: [],
    partnerViews: [],
    brands: [],
    billings: [],
    cashings: [],
    partnerList: [],
    partnerCurrencyList: [],
    partnerNatureList: [],
    billingList: [],
    cashingList: [],
  },
  getters: {
    getPartners: (state) => state.partners,
    getPartnerViews: (state) => state.partnerViews,
    getBrands: (state) => state.brands,
    getBillings: (state) => state.billings,
    getCashings: (state) => state.cashings,
    getPartnerList: (state) => state.partnerList,
    getPartnerCurrencyList: (state) => state.partnerCurrencyList,
    getPartnerNatureList: (state) => state.partnerNatureList,
    getBillingList: (state) => state.billingList,
    getCashingList: (state) => state.cashingList,
  },
  mutations: {
    updatePartners(state, payload) {
      state.partners = payload;
    },
    updatePartnerViews(state, payload) {
      state.partnerViews = payload;
    },
    updateBrands(state, payload) {
      state.brands = payload;
    },
    updateBillings(state, payload) {
      state.billings = payload;
    },
    updateCashings(state, payload) {
      state.cashings = payload;
    },
    updatePartnerList(state, payload) {
      state.partnerList = payload;
    },
    updatePartnerCurrencyList(state, payload) {
      state.partnerCurrencyList = payload;
    },
    updatePartnerNatureList(state, payload) {
      state.partnerNatureList = payload;
    },
    updateBillingList(state, payload) {
      state.billingList = payload;
    },
    updateCashingList(state, payload) {
      state.cashingList = payload;
    },
  },
  actions: {
    updatePartners({ commit }, payload) {
      commit("updatePartners", payload);
    },
    updatePartnerViews({ commit }, payload) {
      commit("updatePartnerViews", payload);
    },
    updateBrands({ commit }, payload) {
      commit("updateBrands", payload);
    },
    updateBillings({ commit }, payload) {
      commit("updateBillings", payload);
    },
    updateCashings({ commit }, payload) {
      commit("updateCashings", payload);
    },
    async getPartnerList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/full_list");
      commit("updatePartnerList", items);

      return items;
    },
    async getPartnerCurrencyList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/currencies");
      commit("updatePartnerCurrencyList", items);

      return items;
    },
    async getPartnerNatureList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/natures");
      commit("updatePartnerNatureList", items);

      return items;
    },
    async getBillingList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/billing_entities");
      commit("updateBillingList", items);

      return items;
    },
    async getCashingList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/cashing_entities");
      commit("updateCashingList", items);

      return items;
    },
  },
};
