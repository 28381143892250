import axios from "@axios";

export default {
  namespaced: true,
  state: {
    applyFilters: false,
    applySearch: false,
    loading: false,
    categoryList: [],
    siteAreaList: [],
    siteBrandList: [],
    siteGroupList: [],
    siteList: [],
    partnerList: [],
    partnerViewGroupList: [],
    partnerViewList: [],
    departmentList: [],
    redac: [],
    initializedFromUrl: {},
    queryString: {},
    queryStringIsUpToDate: false,
    airflowDagList: [],
    lastMepTime: null,
  },
  getters: {
    getApplyFilters: (state) => state.applyFilters,
    getApplySearch: (state) => state.applySearch,
    getLoading: (state) => state.loading,
    getCategoryList: (state) => state.categoryList,
    getSiteAreaList: (state) => state.siteAreaList,
    getSiteBrandList: (state) => state.siteBrandList,
    getSiteGroupList: (state) => state.siteGroupList,
    getSiteList: (state) => state.siteList,
    getPartnerList: (state) => state.partnerList,
    getPartnerViewGroupList: (state) => state.partnerViewGroupList,
    getPartnerViewList: (state) => state.partnerViewList,
    getDepartmentList: (state) => state.departmentList,
    getRedacList: (state) => state.redac,
    getInitializedFromUrl: (state) => state.initializedFromUrl,
    getQueryString: (state) => state.queryString,
    getQueryStringIsUpToDate: (state) => state.queryStringIsUpToDate,
    getAirflowDagList: (state) => state.airflowDagList,
    getLastMepTime: (state) => state.lastMepTime,
  },
  mutations: {
    updateApplyFilters(state, payload) {
      state.applyFilters = payload;
    },
    updateApplySearch(state, payload) {
      state.applySearch = payload;
    },
    updateLoading(state, payload) {
      state.loading = payload;
    },
    updateCategoryList(state, payload) {
      state.categoryList = payload;
    },
    updateSiteAreaList(state, payload) {
      state.siteAreaList = payload;
    },
    updateSiteBrandList(state, payload) {
      state.siteBrandList = payload;
    },
    updateSiteGroupList(state, payload) {
      state.siteGroupList = payload;
    },
    updateSiteList(state, payload) {
      state.siteList = payload;
    },
    updatePartnerList(state, payload) {
      state.partnerList = payload;
    },
    updatePartnerViewGroupList(state, payload) {
      state.partnerViewGroupList = payload;
    },
    updatePartnerViewList(state, payload) {
      state.partnerViewList = payload;
    },
    updateDepartmentList(state, payload) {
      state.departmentList = payload;
    },
    updateRedacList(state, payload) {
      state.redac = payload;
    },
    updateInitializedFromUrl(state, payload) {
      state.initializedFromUrl = payload;
    },
    updateQueryString(state, payload) {
      state.queryString = payload;
    },
    updateQueryStringIsUpToDate(state, payload) {
      state.queryStringIsUpToDate = payload;
    },
    updateAirflowDagList(state, payload) {
      state.airflowDagList = payload;
    },
    updateLastMepTime(state, payload) {
      state.lastMepTime = payload;
    },
  },
  actions: {
    updateApplyFilters({ commit }, payload) {
      return new Promise((resolve) => {
        if (payload === false) {
          setTimeout(() => {
            commit("updateApplyFilters", payload);
            resolve();
          }, 1000);
        } else {
          commit("updateApplyFilters", payload);
          resolve();
        }
      });
    },
    updateApplySearch({ commit }, payload) {
      commit("updateApplySearch", payload);
    },
    updateLoading({ commit }, payload) {
      commit("updateLoading", payload);
    },
    async getCategoryList({ commit }, payload) {
      const {
        data: { items },
      } = await axios.get("/adn-post/categories", {
        params: payload,
      });
      commit("updateCategoryList", items);

      return items;
    },
    async getSiteAreaList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/sites/areas/");
      commit("updateSiteAreaList", items);

      return items;
    },
    async getSiteBrandList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/sites/brands/");
      commit("updateSiteBrandList", items);

      return items;
    },
    async getSiteGroupList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/site-groups/", {
        params: { sort_by: "name" },
      });
      commit("updateSiteGroupList", items);

      return items;
    },
    async getSiteList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/sites/", {
        params: { sort_by: "name" },
      });
      commit("updateSiteList", items);

      return items;
    },
    async getPartnerList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/", {
        params: { order_by: "name" },
      });
      commit("updatePartnerList", items);

      return items;
    },
    async getRedacList({ commit }) {
      const items = await axios.get("/redacs/get_recent_redacs"); // possibility to put a limit if needed  {params: { limit: None }}
      commit("updateRedacList", items.data.res);

      return items.data.res;
    },
    async getPartnerViewGroupList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partner-view-groups/", {
        params: { sort_by: "name" },
      });
      commit("updatePartnerViewGroupList", items);

      return items;
    },
    async getPartnerViewList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partner-views/", {
        params: { order_by: "label" },
      });
      commit("updatePartnerViewList", items);

      return items;
    },
    async getDepartmentList({ commit }) {
      const { data } = await axios.get("/departments/");
      commit("updateDepartmentList", data);

      return data;
    },
    setInitializedFromUrl({ commit, getters }, payload) {
      const keys = Object.keys(payload);
      if (!keys.includes("param") || !keys.includes("status")) {
        return;
      }
      commit("updateInitializedFromUrl", {
        ...getters["getInitializedFromUrl"],
        [payload.param]: !!payload.status,
      });
    },
    isInitializingFromUrl({ getters }, payload) {
      return payload in getters["getInitializedFromUrl"];
    },
    isInitializedFromUrl({ getters }, payload) {
      return (
        payload in getters["getInitializedFromUrl"] &&
        getters["getInitializedFromUrl"][payload]
      );
    },
    initializeQueryString({ commit }, payload) {
      commit("updateQueryString", payload);
      commit("updateQueryStringIsUpToDate", true);
    },
    setQueryStringInitialized({ commit }) {
      commit("updateQueryStringIsUpToDate", true);
    },
    addQueryStringParam({ commit, getters }, payload) {
      const keys = Object.keys(payload);
      if (!keys.includes("param") || !keys.includes("value")) {
        return;
      }
      commit("updateQueryStringIsUpToDate", false);
      commit("updateQueryString", {
        ...getters["getQueryString"],
        [payload.param]: payload.value,
      });
    },
    async collectAirflowDagList({ commit }) {
      if (
        process.env.NODE_ENV !== "production" &&
        (!("VUE_APP_AIRFLOW_ENABLED" in process.env) ||
          process.env.VUE_APP_AIRFLOW_ENABLED !== "true")
      ) {
        return [];
      }

      const {
        data: { items },
      } = await axios.get("/airflow/dag/list");
      commit("updateAirflowDagList", items ?? []);

      return items;
    },
    async updateLastMepTime({ commit }, payload) {
      commit("updateLastMepTime", payload);
    },
  },
};
