import { mdiSpeedometer } from "@mdi/js";

const Nav = [
  {
    title: "Performances Web",
    icon: mdiSpeedometer,
    children: [
      {
        title: "CWV",
        resource: "web_perf",
        action: "manage",
        children: [
          {
            title: "Performance des sites",
            to: "cwv-sites-performance",
            resource: "web_perf",
            action: "manage",
          },
          // {
          //   title: "Performance en détail",
          //   to: "cwv-performance-breakdown",
          // },
          {
            title: "Changements de Pallier",
            to: "cwv-threshold-changes",
            resource: "web_perf",
            action: "manage",
          },
          {
            title: "Données consolidées par jour",
            to: "cwv-daily-data",
            resource: "web_perf",
            action: "manage",
          },
          {
            title: "Suivi des tickets",
            to: "cwv-issues",
            resource: "web_perf",
            action: "manage",
          },
        ],
      },
    ],
  },
];

export default Nav;
