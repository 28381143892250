import axios from "@axios";

export const getSiteIdsForGroups = async (groups) => {
  const { data } = await axios.get("/site-groups/sites", {
    params: { group_ids: groups.join(",") },
  });

  // get siteIds only and remove duplicates
  return [...new Set(data.map((x) => x.site_id))].sort();
};

export const getPartnerViewIdsForGroups = async (groups) => {
  const { data } = await axios.get("/partner-view-groups/partner-views", {
    params: { group_ids: groups.join(",") },
  });

  // get site_ids only and remove duplicates
  return [...new Set(data.map((x) => x.partner_view_id))].sort();
};
