import { mdiTarget } from "@mdi/js";

const regieNav = [
  {
    title: "Régie",
    icon: mdiTarget, // mdiEye,
    children: [
      {
        title: "Audience par article",
        to: "regie-perf",
        resource: "regie",
        action: "manage",
      },
      {
        title: "Data visiteurs",
        to: "regie-visitors",
        resource: "regie",
        action: "manage",
      },
      {
        title: "Prévision inventaire",
        to: "regie-forecast",
        resource: "regie",
        action: "manage",
      },
      {
        title: "Marmiton",
        to: "regie-marmiton",
        resource: "regie",
        action: "manage",
      },
      {
        title: "Inventaire vidéo",
        to: "regie-video-inventory",
        resource: "regie",
        action: "manage",
      },
    ],
  },
];

export default regieNav;
