import generalNav from "./general";
import ctcNav from "./ctc";
import programmatiqueNav from "./programmatique";
import arbitrageNav from "./arbitrage";
import seoNav from "./seo";
import crmNav from "./crm";
import audienceNav from "./audience";
import regieNav from "./regie";
import marmitonNav from "./marmiton";
import partners from "./partners";
import adminNav from "./admin";
import financeNav from "./finance";
import googleDiscoverNav from "./googleDiscover";
import rmrnAnalyticsNav from "./rmrAnalytics";
import LinkMessagingApps from "./linkMessagingApps";
import webPerf from "./webPerf";
import gTrends from "./gTrends";

export default [
  ...generalNav,
  ...audienceNav,
  ...programmatiqueNav,
  ...regieNav,
  ...marmitonNav,
  ...googleDiscoverNav,
  ...LinkMessagingApps,
  ...gTrends,
  ...arbitrageNav,
  ...webPerf,
  ...ctcNav,
  // ...seoNav,
  ...crmNav,
  ...financeNav,
  ...rmrnAnalyticsNav,
  ...partners,
  ...adminNav,
];
