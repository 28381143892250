import { getSiteIdsForGroups } from "@/utils/store";

export default {
  namespaced: true,
  state: {
    dates: [],
    sites: [],
    siteGroups: [],
  },
  getters: {
    getDates: (state) => {
      // always return the dates ordered
      return state.dates[0] < state.dates[1]
        ? [state.dates[0], state.dates[1]]
        : [state.dates[1], state.dates[0]];
    },
    getSites: (state) => {
      return state.sites;
    },
    getSiteGroups: (state) => {
      return state.siteGroups;
    },
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateDates", payload);
      }
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);

      const site_ids = await getSiteIdsForGroups(payload);
      commit("updateSites", site_ids);

      return site_ids;
    },
  },
};
