import axios from "@axios";

import { getPartnerViewIdsForGroups, getSiteIdsForGroups } from "@/utils/store";

export default {
  namespaced: true,
  state: {
    monthDate: "",
    sites: [],
    siteGroups: [],
    audienceType: "ga",
    rwmData: {},
    partnersViews: [],
    partnerViewGroups: [],
  },
  getters: {
    getMonthDate: (state) => state.monthDate,
    getSites: (state) => state.sites,
    getSiteGroups: (state) => state.siteGroups,
    getAudienceType: (state) => state.audienceType,
    getRwmData: (state) => state.rwmData,
    getPartnerViewGroups: (state) => state.partnerViewGroups,
    getPartnerViews: (state) => state.partnersViews,
  },
  mutations: {
    updateMonthDate(state, payload) {
      state.monthDate = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updateAudienceType(state, payload) {
      state.audienceType = payload;
    },
    updateRwmData(state, payload) {
      state.rwmData = payload;
    },
    updatePartnerViews(state, payload) {
      state.partnersViews = payload;
    },
    updatePartnerViewGroups(state, payload) {
      state.partnerViewGroups = payload;
    },
  },
  actions: {
    updateMonthDate({ commit }, payload) {
      commit("updateMonthDate", payload);
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);

      const siteIds = await getSiteIdsForGroups(payload);
      commit("updateSites", siteIds);

      return siteIds;
    },
    updateRwmData({ commit }, payload) {
      commit("updateRwmData", payload);
    },
    updateAudienceType({ commit }, payload) {
      commit("updateAudienceType", payload);
    },
    async getRwmData({ commit }, payload) {
      const response = await axios.get("/audience/rwm-data", {
        params: payload,
      });
      commit("updateRwmData", response.data);
    },
    updatePartnerViews({ commit }, payload) {
      commit("updatePartnerViews", payload);
    },
    async updatePartnerViewGroups({ commit }, payload) {
      commit("updatePartnerViewGroups", payload);
      if (!payload.length) {
        commit("updatePartnerViews", []);
        return;
      }

      const partner_views_ids = await getPartnerViewIdsForGroups(payload);
      commit("updatePartnerViews", partner_views_ids);

      return partner_views_ids;
    },
  },
};
