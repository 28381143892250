import appConfigStoreModule from "@core/@app-config/appConfigStoreModule";
import Vue from "vue";
import Vuex from "vuex";

import adb from "./adb";
import admin from "./admin";
import alerts from "./alerts";
import app from "./app";
import audience from "./audience";
import axios from "./axios";
import c2c from "./c2c";
import common from "./common";
import finance from "./finance";
import general from "./general";
import gd from "./gd";
import marmiton from "./marmiton";
import premium from "./premium";
import regie from "./regie";
import rmra from "./rmra";
import seo from "./seo";
import redac from "./redac";
import webPerf from "./web-perf";
import linkMessApps from "./link-mess-apps";
import gtrends from "./gtrends";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    adb,
    admin,
    alerts,
    app,
    audience,
    axios,
    c2c,
    common,
    finance,
    general,
    gd,
    marmiton,
    premium,
    regie,
    rmra,
    seo,
    redac,
    webPerf,
    linkMessApps,
    gtrends,
  },
});
