const webPerfRoutes = [
  {
    path: "/web-perf/cwv-sites-performance",
    name: "cwv-sites-performance",
    component: () => import("@/views/web-perf/CwvSitesPerformance.vue"),
    meta: {
      layout: "content",
      resource: "web_perf",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "cwv-sites-performance",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/web-perf/cwv-performance-breakdown",
    name: "cwv-performance-breakdown",
    component: () => import("@/views/web-perf/CwvPerformanceBreakdown.vue"),
    meta: {
      layout: "content",
      resource: "web_perf",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "cwv-performance-breakdown",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/web-perf/cwv-threshold-changes",
    name: "cwv-threshold-changes",
    component: () => import("@/views/web-perf/CwvThresholdChanges.vue"),
    meta: {
      layout: "content",
      resource: "web_perf",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "cwv-threshold-changes",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/web-perf/cwv-daily-data",
    name: "cwv-daily-data",
    component: () => import("@/views/web-perf/CwvDailyData.vue"),
    meta: {
      layout: "content",
      resource: "web_perf",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "cwv-daily-data",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/web-perf/cwv-raw-data",
    name: "cwv-raw-data",
    component: () => import("@/views/web-perf/CwvRawData.vue"),
    meta: {
      layout: "content",
      resource: "web_perf",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "cwv-daily-data",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/web-perf/cwv-issues",
    name: "cwv-issues",
    component: () => import("@/views/web-perf/CwvIssues.vue"),
    meta: {
      layout: "content",
      resource: "web_perf",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "cwv-issues",
        eventCategory: "page-view",
      },
    },
  },
];

export default webPerfRoutes;
