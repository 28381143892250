<template>
  <div class="app-content-container mx-auto h-full" :class="appContentClass">
    <slot></slot>
  </div>
</template>

<script>
import useAppConfig from "@core/@app-config/useAppConfig";
import { computed } from "@vue/composition-api";

export default {
  setup() {
    const { appContentWidth } = useAppConfig();
    const appContentClass = computed(() => {
      const classes = {
        boxed: "app-content-container-boxed",
        fullWidth: "app-content-container-full",
        largeWidth: "app-content-container-large",
      };
      return classes[appContentWidth.value];
    });

    return {
      appContentClass,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/layouts/styles/_variables";

.app-content-container {
  &.app-content-container-boxed {
    max-width: $content-max-width;
  }
  &.app-content-container-full {
    max-width: 100%;
  }
  &.app-content-container-large {
    max-width: 1800px;
  }
}
</style>
