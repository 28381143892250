import { mdiCog, mdiHandshake } from "@mdi/js";

const Nav = [
  {
    title: "Partenaires",
    icon: mdiHandshake,
    children: [
      {
        title: "Liste",
        to: "admin-partners",
        resource: "all",
        action: "manage",
      },
      {
        title: "Editer",
        to: "admin-partner-edit",
        resource: "all",
        action: "manage",
      },
      {
        title: "Vues de partenaires",
        resource: "partner-view",
        resource: "admin",
        action: "manage",
        children: [
          {
            title: "Liste",
            to: "admin-list-partner-view",
            resource: "admin",
            action: "manage",
          },
          {
            title: "Créer / éditer",
            to: "admin-edit-partner-view",
            resource: "admin",
            action: "manage",
          },
        ],
      },
      {
        title: "Partner accounts",
        resource: "all",
        action: "manage",
        children: [
          {
            title: "Liste",
            to: "admin-partner-accounts",
            resource: "all",
            action: "manage",
          },
          {
            title: "Créer / éditer",
            to: "admin-partner-account-edit",
            resource: "all",
            action: "manage",
          },
          {
            title: "Data",
            to: "admin-partner-data",
            resource: "all",
            action: "manage",
          },
        ],
      },
      {
        title: "Relations partenaires",
        resource: "all",
        action: "manage",
        children: [
          {
            title: "Liste",
            to: "admin-partner-relations",
            resource: "all",
            action: "manage",
          },
          {
            title: "Créer / éditer",
            to: "admin-partner-relation-edit",
            resource: "all",
            action: "manage",
          },
        ],
      },
      {
        title: "Groupes de vues de partenaires",
        resource: "partner-view-group",
        resource: "admin",
        action: "manage",
        children: [
          {
            title: "Liste des groupes de vues de partenaires",
            to: "admin-list-group-partner-view",
            resource: "admin",
            action: "manage",
          },
          {
            title: "Créer/éditer un groupe de vues de partenaires",
            to: "admin-edit-group-partner-view",
            resource: "admin",
            action: "manage",
          },
        ],
      },
    ],
  },
];

export default Nav;
