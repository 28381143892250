import axios from "@axios";

export default {
  namespaced: true,
  state: {
    sites: [2, 52, 139, 133, 24, 25, 22, 127, 39, 13],
    periods: [],
    dates: [],
    periodType: "",
    globalStats: {},
  },
  getters: {
    getSites: (state) => {
      return state.sites;
    },
    getPeriods: (state) => {
      return state.periods;
    },
    getPeriodType: (state) => {
      return state.periodType;
    },
    getDates: (state) => {
      return state.dates;
    },
    getGlobalStats: (state) => {
      return state.globalStats;
    },
  },
  mutations: {
    updateGlobalStats(state, payload) {
      state.globalStats = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    setPeriods(state, payload) {
      state.periods = payload;
    },
    updatePeriodType(state, payload) {
      state.periodType = payload;
    },
    updateDates(state, payload) {
      state.dates = payload;
    },
  },
  actions: {
    updateGlobalStats(context, payload) {
      context.commit("updateGlobalStats", payload);
    },
    updateSites(context, payload) {
      context.commit("updateSites", payload);
    },
    setPeriods(context, payload) {
      context.commit("setPeriods", payload);
    },
    updatePeriodType({ commit }, payload) {
      commit("updatePeriodType", payload);
    },
    updateDates({ commit }, payload) {
      commit("updateDates", payload);
    },
  },
};
