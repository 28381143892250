import axios, { cacheTime } from "@axios";

import { getPartnerViewIdsForGroups, getSiteIdsForGroups } from "@/utils/store";

export default {
  namespaced: true,
  state: {
    monthDate: "",
    dates: [],
    sites: [],
    siteGroups: [],
    partnersList: [],
    partnersViews: [],
    partnerViewGroups: [],
    groupCA: true,
  },
  getters: {
    getDates: (state) => state.dates,
    getSites: (state) => state.sites,
    getSiteGroups: (state) => state.siteGroups,
    getPartnerViewGroups: (state) => state.partnerViewGroups,
    getPartnerViews: (state) => state.partnersViews,
    getPartnersList: (state) => state.partnersList,
    getMonthDate: (state) => state.monthDate,
    getGroupCA: (state) => state.groupCA,
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updatePartnerViews(state, payload) {
      state.partnersViews = payload;
    },
    updatePartnerViewGroups(state, payload) {
      state.partnerViewGroups = payload;
    },
    updatePartnersList(state, payload) {
      state.partnersList = payload;
    },
    updateMonthDate(state, payload) {
      state.monthDate = payload;
    },
    updateGroupCA(state, payload) {
      state.groupCA = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      commit("updateDates", payload);
    },
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);

      const siteIds = await getSiteIdsForGroups(payload);
      commit("updateSites", siteIds);

      return siteIds;
    },
    async updatePartnerViewGroups({ commit }, payload) {
      commit("updatePartnerViewGroups", payload);
      if (!payload.length) {
        commit("updatePartnerViews", []);
        return;
      }

      const partner_views_ids = await getPartnerViewIdsForGroups(payload);
      commit("updatePartnerViews", partner_views_ids);
      return partner_views_ids;
    },
    async getPartnersList({ commit }) {
      const {
        data: { items },
      } = await axios.get("/partners/");
      commit("updatePartnersList", items);

      return items;
    },
    async getDetailedRevenue({ commit }, payload) {
      const { data } = await axios.get(
        "/programmatic/detailed-revenue-monthly-report",
        {
          params: payload,
          cache: {
            maxAge: cacheTime,
          },
        }
      );

      return data;
    },
    updateMonthDate({ commit }, payload) {
      commit("updateMonthDate", payload);
    },
    updatePartnerViews({ commit }, payload) {
      commit("updatePartnerViews", payload);
    },
    updateGroupCA({ commit }, payload) {
      commit("updateGroupCA", payload);
    },
  },
};
