import { getSiteIdsForGroups } from "@/utils/store";

export default {
  namespaced: true,
  state: {
    sites: [],
    siteGroups: [],
    dates: [],
    data: [],
    categories: [],
    periodType: "articles_seen",
    globalData: {
      total: 0,
      multi_repub: 0,
      ca: 0,
      ca_before: 0,
      ca_after: 0,
      ca_percent: 0,
      sessions: 0,
      sessions_before: 0,
      sessions_after: 0,
      sessions_percent: 0,
    },
  },
  getters: {
    getSites: (state) => state.sites,
    getSiteGroups: (state) => state.siteGroups,
    getDates: (state) => {
      // always return the dates ordered
      return state.dates[0] < state.dates[1]
        ? [state.dates[0], state.dates[1]]
        : [state.dates[1], state.dates[0]];
    },
    getData: (state) => state.data,
    getCategories: (state) => state.categories,
    getPeriodType: (state) => state.periodType,
    getGlobalData: (state) => state.globalData,
  },
  mutations: {
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateData(state, payload) {
      state.data = payload;
    },
    updateCategories(state, payload) {
      state.categories = payload;
    },
    updatePeriodType(state, payload) {
      state.periodType = payload;
    },
    updateGlobalData(state, payload) {
      state.globalData = payload;
    },
  },
  actions: {
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);

      const site_ids = await getSiteIdsForGroups(payload);
      commit("updateSites", site_ids);

      return site_ids;
    },
    updateDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateDates", payload);
      }
    },
    updateData({ commit }, payload) {
      commit("updateData", payload);
    },
    updateCategories({ commit }, payload) {
      commit("updateCategories", payload);
    },
    updatePeriodType({ commit }, payload) {
      commit("updatePeriodType", payload);
    },
    updateGlobalData({ commit }, payload) {
      commit("updateGlobalData", payload);
    },
  },
};
