import axios from "@axios";

export default {
  namespaced: true,
  state: {
    dates: [],
    minSearchVolume: 0,
    isActive: "",
    category: "",
  },
  getters: {
    getDates: (state) => {
      return state.dates;
    },
    getMinSearchVolume: (state) => {
      return state.minSearchVolume;
    },
    getisActive: (state) => {
      return state.isActive;
    },
    getCategory: (state) => {
      return state.category;
    },
  },
  mutations: {
    updateDates(state, payload) {
      state.dates = payload;
    },
    updateMinSearchVolume(state, payload) {
      state.minSearchVolume = payload;
    },
    updateisActive(state, payload) {
      state.isActive = payload;
    },
    updateCategory(state, payload) {
      state.category = payload;
    },
  },
  actions: {
    updateDates({ commit }, payload) {
      commit("updateDates", payload);
    },
    updateMinSearchVolume({ commit }, payload) {
      commit("updateMinSearchVolume", payload);
    },
    updateisActive({ commit }, payload) {
      commit("updateisActive", payload);
    },
    updateCategory({ commit }, payload) {
      commit("updateCategory", payload);
    },
  },
};
