import axios from "@axios";

import { getSiteIdsForGroups } from "@/utils/store";

export default {
  namespaced: true,
  state: {
    sites: [],
    siteGroups: [],
    redacs: [],

    // BySource :
    yearDate: null,
    sourceId: [],
    listSourceId: [],
    exactSearch: false,
    containsSource: null,

    // PerfPerArticle / PerfPerCategory :
    categories: [],
    dates: [],
    periodType: "",
    statsForPeriod: {},

    // PerfPerArticle :
    searchType: "",
    searchColumn: "",

    // PerfPerCategory :
    excludeCategories: false,
    channels: [],
    channelList: [],

    //RentaPerArticle :
    rentaGlobalStats: {},
    monthDate: "",

    // ACPM :
    acpmIndicator: "",
    acpmIndicatorList: [],
    acpmBusinesses: [],
    acpmBusinessList: [],
    acpmSites: [],
    acpmSiteList: [],
    acpmThematics: [],
    acpmThematicList: [],
    acpmSubThematics: [],
    acpmSubThematicList: [],
  },
  getters: {
    getSites: (state) => state.sites,
    getSiteGroups: (state) => state.siteGroups,
    getRedacs: (state) => state.redacs,

    // BySource :
    getYearDate: (state) => state.yearDate,
    getSourceId: (state) => state.sourceId,
    getListSourceId: (state) => state.listSourceId,
    getExactSearch: (state) => state.exactSearch,
    getContainsSource: (state) => state.containsSource,

    // PerfPerArticle / PerfPerCategory :
    getCategories: (state) => state.categories,
    getDates: (state) => {
      if (state.dates.length !== 2) {
        return [];
      }
      // always return the dates ordered
      return state.dates[0] < state.dates[1]
        ? [state.dates[0], state.dates[1]]
        : [state.dates[1], state.dates[0]];
    },
    getPeriodType: (state) => state.periodType,
    getStatsForPeriod: (state) => state.statsForPeriod,

    // PerfPerArticle :
    getSearchType: (state) => state.searchType,
    getSearchColumn: (state) => state.searchColumn,

    // PerfPerCategory :
    getExcludeCategories: (state) => state.excludeCategories,
    getChannels: (state) => state.channels,
    getChannelList: (state) => state.channelList,

    //RentaPerArticle :
    getRentaGlobalStats: (state) => state.rentaGlobalStats,
    getMonthDate: (state) => state.monthDate,

    // ACPM :
    getAcpmIndicator: (state) => state.acpmIndicator,
    getAcpmIndicatorList: (state) => state.acpmIndicatorList,
    getAcpmBusinesses: (state) => state.acpmBusinesses,
    getAcpmBusinessList: (state) => state.acpmBusinessList,
    getAcpmSites: (state) => state.acpmSites,
    getAcpmSiteList: (state) => state.acpmSiteList,
    getAcpmThematics: (state) => state.acpmThematics,
    getAcpmThematicList: (state) => state.acpmThematicList,
    getAcpmSubThematics: (state) => state.acpmSubThematics,
    getAcpmSubThematicList: (state) => state.acpmSubThematicList,
  },
  mutations: {
    setRedacs(state, payload) {
      state.redacs = payload;
    },
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },

    // BySource :
    updateYearDate(state, payload) {
      state.yearDate = payload;
    },
    updateSourceId(state, payload) {
      state.sourceId = payload;
    },
    updateListSourceId(state, payload) {
      state.listSourceId = payload;
    },
    updateExactSearch(state, payload) {
      state.exactSearch = payload;
    },
    updateContainsSource(state, payload) {
      state.containsSource = payload;
    },

    // PerfPerArticle / PerfPerCategory :
    updateCategories(state, payload) {
      state.categories = payload;
    },
    updateDates(state, payload) {
      state.dates = payload;
    },
    updatePeriodType(state, payload) {
      state.periodType = payload;
    },
    updateStatsForPeriod(state, payload) {
      state.statsForPeriod = payload;
    },

    // PerfPerArticle :
    updateSearchType(state, payload) {
      state.searchType = payload;
    },
    updateSearchColumn(state, payload) {
      state.searchColumn = payload;
    },

    // PerfPerCategory :
    updateExcludeCategories(state, payload) {
      state.excludeCategories = payload;
    },
    updateChannels(state, payload) {
      state.channels = payload;
    },
    updateChannelList(state, payload) {
      state.channelList = payload;
    },

    //RentaPerArticle :
    updateRentaGlobalStats(state, payload) {
      state.rentaGlobalStats = payload;
    },
    updateMonthDate(state, payload) {
      state.monthDate = payload;
    },

    // ACPM :
    updateAcpmIndicator(state, payload) {
      state.acpmIndicator = payload;
    },
    updateAcpmIndicatorList(state, payload) {
      state.acpmIndicatorList = payload;
    },
    updateAcpmBusinesses(state, payload) {
      state.acpmBusinesses = payload;
    },
    updateAcpmBusinessList(state, payload) {
      state.acpmBusinessList = payload;
    },
    updateAcpmSites(state, payload) {
      state.acpmSites = payload;
    },
    updateAcpmSiteList(state, payload) {
      state.acpmSiteList = payload;
    },
    updateAcpmThematics(state, payload) {
      state.acpmThematics = payload;
    },
    updateAcpmThematicList(state, payload) {
      state.acpmThematicList = payload;
    },
    updateAcpmSubThematics(state, payload) {
      state.acpmSubThematics = payload;
    },
    updateAcpmSubThematicList(state, payload) {
      state.acpmSubThematicList = payload;
    },
  },
  actions: {
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);

      const site_ids = await getSiteIdsForGroups(payload);
      commit("updateSites", site_ids);

      return site_ids;
    },
    setRedacs(context, payload) {
      context.commit("setRedacs", payload);
    },

    // BySource :
    updateYearDate({ commit }, payload) {
      commit("updateYearDate", payload);
    },
    updateSourceId({ commit }, payload) {
      commit("updateSourceId", payload);
    },
    updateListSourceId({ commit }, payload) {
      commit("updateListSourceId", payload);
    },
    updateExactSearch({ commit }, payload) {
      commit("updateExactSearch", payload);
    },
    updateContainsSource({ commit }, payload) {
      commit("updateContainsSource", payload);
    },

    // PerfPerArticle / PerfPerCategory :
    updateCategories({ commit }, payload) {
      commit("updateCategories", payload);
    },
    updateDates({ commit }, payload) {
      if (payload.length >= 2) {
        commit("updateDates", payload);
      }
    },
    updatePeriodType({ commit }, payload) {
      commit("updatePeriodType", payload);
    },
    updateStatsForPeriod({ commit }, payload) {
      commit("updateStatsForPeriod", payload);
    },

    // PerfPerArticle :
    updateSearchType({ commit }, payload) {
      commit("updateSearchType", payload);
    },
    updateSearchColumn({ commit }, payload) {
      commit("updateSearchColumn", payload);
    },

    // PerfPerCategory :
    updateExcludeCategories({ commit }, payload) {
      commit("updateExcludeCategories", payload);
    },
    async getChannelList({ commit }, payload) {
      const { data } = await axios.get("/adn-post/channels", {
        params: payload,
      });
      commit("updateChannelList", data);

      return data;
    },
    updateChannels({ commit }, payload) {
      commit("updateChannels", payload);
    },

    //RentaPerArticle :
    updateRentaGlobalStats({ commit }, payload) {
      commit("updateRentaGlobalStats", payload);
    },
    updateMonthDate({ commit }, payload) {
      commit("updateMonthDate", payload);
    },

    // ACPM :
    async getAcpmIndicatorList({ commit }) {
      const { data } = await axios.get("/audience/acpm/indicators");
      commit("updateAcpmIndicatorList", data);

      return data;
    },
    updateAcpmIndicator({ commit }, payload) {
      commit("updateAcpmIndicator", payload);
    },
    async getAcpmBusinessList({ commit }) {
      const { data } = await axios.get("/audience/acpm/businesses");
      commit("updateAcpmBusinessList", data);

      return data;
    },
    updateAcpmBusinesses({ commit }, payload) {
      commit("updateAcpmBusinesses", payload);
    },
    async getAcpmSiteList({ commit }) {
      const { data } = await axios.get("/audience/acpm/sites");
      commit("updateAcpmSiteList", data);

      return data;
    },
    updateAcpmSites({ commit }, payload) {
      commit("updateAcpmSites", payload);
    },
    async getAcpmThematicList({ commit }) {
      const { data } = await axios.get("/audience/acpm/thematics");
      commit("updateAcpmThematicList", data);

      return data;
    },
    updateAcpmThematics({ commit }, payload) {
      commit("updateAcpmThematics", payload);
    },
    async getAcpmSubThematicList({ commit }) {
      const { data } = await axios.get("/audience/acpm/sub-thematics");
      commit("updateAcpmSubThematicList", data);

      return data;
    },
    updateAcpmSubThematics({ commit }, payload) {
      commit("updateAcpmSubThematics", payload);
    },
  },
};
