import { mdiMessageBadge } from "@mdi/js";

const LinkMessagingApps = [
  {
    title: "Perf audience sur messagerie",
    icon: mdiMessageBadge,
    children: [
      {
        title: "Suivi des liens",
        to: "link-messaging-apps",
        resource: "link_messapps",
        action: "manage",
      },
      {
        title: "Publication ponctuelle",
        to: "one-shot-link",
        resource: "link_messapps",
        action: "manage",
      },
    ],
  },
];

export default LinkMessagingApps;
