import { getSiteIdsForGroups } from "@/utils/store";

export default {
  namespaced: true,
  state: {
    sites: [],
    siteGroups: [],
    monthDate: "",
  },
  getters: {
    getSites: (state) => state.sites,
    getSiteGroups: (state) => state.siteGroups,
    getMonthDate: (state) => state.monthDate,
  },
  mutations: {
    updateSites(state, payload) {
      state.sites = payload;
    },
    updateSiteGroups(state, payload) {
      state.siteGroups = payload;
    },
    updateMonthDate(state, payload) {
      state.monthDate = payload;
    },
  },
  actions: {
    updateSites({ commit }, payload) {
      commit("updateSites", payload);
    },
    async updateSiteGroups({ commit }, payload) {
      commit("updateSiteGroups", payload);

      const site_ids = await getSiteIdsForGroups(payload);
      commit("updateSites", site_ids);

      return site_ids;
    },
    updateMonthDate({ commit }, payload) {
      commit("updateMonthDate", payload);
    },
  },
};
