const arbitrageRoutes = [
  {
    path: "/arbitrage/campain-main",
    name: "campain-main",
    component: () => import("@/views/arbitrage_campaign/Index.vue"),
    meta: {
      layout: "content",
      resource: "arbitrage_campaign",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "arbitrage-campain-main",
        eventCategory: "page-view",
      },
    },
  },
  {
    path: "/arbitrage/campain-tracker",
    name: "campain-tracker",
    component: () => import("@/views/arbitrage_campaign/Tracker.vue"),
    meta: {
      layout: "content",
      resource: "arbitrage_campaign",
      action: "manage",
    },
  },
  {
    path: "/arbitrage/campaign-redirect",
    name: "campaign-redirect",
    component: () => import("@/views/arbitrage_campaign/CampaignRedirect.vue"),
    meta: {
      layout: "content",
      resource: "arbitrage_campaign",
      action: "manage",
      rmrAnalytics: {
        track: true,
        eventType: "campaign-redirect-create-link",
        eventCategory: "page-view",
      },
    },
  },
];

export default arbitrageRoutes;
