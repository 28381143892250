import { mdiCog } from "@mdi/js";

const Nav = [
  {
    title: "Admin",
    icon: mdiCog,
    children: [
      {
        title: "Univers",
        resource: "admin",
        action: "manage",
        children: [
          {
            title: "Liste des univers",
            to: "admin-list-group-site",
            resource: "admin",
            action: "manage",
          },
          {
            title: "Créer/éditer un univers",
            to: "admin-edit-group-site",
            resource: "admin",
            action: "manage",
          },
        ],
      },
      {
        title: "Rapports",
        resource: "admin",
        action: "manage",
        children: [
          {
            title: "Liste des rapports",
            to: "admin-list-email-report",
            resource: "admin",
            action: "manage",
          },
          {
            title: "Créer un rapport",
            to: "admin-create-email-report",
            resource: "admin",
            action: "manage",
          },
        ],
      },
      {
        title: "Alertes",
        resource: "admin",
        action: "manage",
        children: [
          {
            title: "Liste des rapports d'alerte",
            to: "admin-list-alert-report",
            resource: "admin",
            action: "manage",
          },
          {
            title: "Créer un rapport d'alerte",
            to: "admin-create-alert-report",
            resource: "admin",
            action: "manage",
          },
        ],
      },
      {
        title: "Sites",
        resource: "admin",
        action: "manage",
        children: [
          {
            title: "Liste",
            to: "admin-list-site",
            resource: "admin",
            action: "manage",
          },
          {
            title: "Créer / éditer",
            to: "admin-edit-site",
            resource: "admin",
            action: "manage",
          },
        ],
      },
      {
        title: "Marmiton",
        resource: "all",
        action: "manage",
        children: [
          {
            title: "Groupes d'ingrédients",
            to: "admin-marmiton-list-ingredient-groups",
            resource: "all",
            action: "manage",
          },
          {
            title: "Créer un groupe d'ingrédients",
            to: "admin-marmiton-create-ingredient-group",
            resource: "all",
            action: "manage",
          },
        ],
      },
      {
        title: "Notifications",
        to: "notifications",
        resource: "all",
        action: "manage",
      },
      {
        title: "Workflows",
        to: "admin-aiflow-start-dag",
        resource: "all",
        action: "manage",
      },
    ],
  },
];

export default Nav;
